import React, { Component } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const isLoggedIn = useSelector(state => state.token) 
    const dispatch = useDispatch()
  if (!isLoggedIn) {
      console.log("private route", location.pathname)
      dispatch({  type: 'SET_AFTERLOGIN_PATH', afterLoginPath: location.pathname})
    navigate("/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute