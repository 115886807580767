import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Layout from "./layout"
import {
  Button,
  NeedHelp,
  Avantages,
  Spacer,
  Title,
  Input,
  Radios,
  Loader,
} from "./banners"
import { logout, getOrders } from "../actions"
import { validEmail, validString } from "../utils/utils"
import { orderStatus } from "../config"
import { ContextualCart } from "./cart"

const Logout = () => {
    const  dispatch = useDispatch()
    return (
        <Button type='secondary' onClick={() => dispatch(logout())} >
            Déconnexion
        </Button>
    )
}

const Orders = () => {
  const orders = useSelector(state => state.orders)
  const  dispatch = useDispatch()
  useEffect(() => {dispatch(getOrders()); return}, [])
  if (typeof orders === "undefined") return (<div>
    <Loader />
  </div>)
  if (orders.length === 0) return (<div>Aucune commande pour le moment.</div>)
  return (
    <div>
      {orders.map(({_id, cart, member, status, message, tracking, 'created-at': createdAt}) => {
        const date = new Date(createdAt)
        return (
        <div key={_id} style={{border: '1px solid black', padding:  '20px'}}>
          <Title level="2" style={{textAlign: 'left'}}>
            Commande du {date.toLocaleDateString()} 
            &nbsp; – <span style={{color: 'green'}}> {orderStatus(status)}</span>
        </Title>
        {validString(message) && <div style={{marginBottom: '2rem'}}>{message}</div>}
        {validString(tracking) && <div style={{marginBottom: '2rem'}}>
          Suivi de colis : <a href={tracking} target="_blank" className="external">{tracking}</a></div>}
        <div style={{width: '400px'}}>
          <ContextualCart cart={cart} />
        </div>
        </div>
        )
})}
    </div>
  )
}

export default () => {
  return (
      <div style={{  }}>
        <Title style={{textAlign: 'left'}}>Mes commandes</Title>
        <Orders />
        <Spacer v="6rem" />
        <Logout />
        <Spacer v="6rem" />
      <Avantages />
      </div>
  )
}
