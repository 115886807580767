import React, {useEffect, useState} from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import {Spacer, Avantages} from "../components/banners"
import Cart from "../components/cart"
import Profile from "../components/profile"
import PrivateRoute from "../components/privateRoute"
 
// https://reactjs.org/docs/react-dom.html#hydrate
// two-pass rendering to avoid an initial rendering that would be very different
// from SSR and break everything.

const App = () => {
  const [c, setC] = useState(false)
  useEffect(() => {
    setC(true)
  }, [])
  console.log("app", c)
  return (
    <Layout>
      { c ? 
      (<Router basepath="/app">
        <Cart path="/cart" />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/profile2" component={Avantages} />
        <Avantages path="/" />
      </Router>) : (<div>...</div>) }
    </Layout>)
}
export default App